<template>
  <el-container class="home scrollbar">
    <div class="home-dj">
      <el-aside
        class="el-aside scrollbar"
        style="width: ''"
        :style="{ background: $store.state.theme }"
      >
        <div class="header">
          <!-- <img v-if="isCollapse" src="../assets/image/logo.png" alt="" /> -->
          <div class="sn">
            <!-- <img src="../assets/image/logo.png" alt="" /> -->
            <img src="../assets/image/login.png" alt="" />
          </div>
        </div>
        <el-menu
          class="el-menu-vertical-demo"
          text-color="hsla(0,0%,100%,.65)"
          background-color="#001529"
          active-text-color="#fff"
          :collapse="isCollapse"
          router
          :default-active="indexpath"
          :unique-opened="true"
        >
          <!-- <el-menu-item
            index="homePage"
            v-show="!isCollapse"
            @click="click('homePage')"
          >
            <span slot="title"
              ><svg-icon
                icon-class="dashboard"
                style="margin-right: 10px"
              />首頁</span
            >
          </el-menu-item> -->
          <Menu
            :menuData="menulists"
            :isCollapse="isCollapse"
            @linkClick="linkClick"
          />
        </el-menu>
      </el-aside>
    </div>
    <el-container class="container">
      <el-header class="header" style="height: 50px">
        <div class="header-top">
          <div class="home-left">
            <!-- <i
              :class="[isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold']"
              @click="picture"
            ></i> -->
            <div class="header_bottom_right">{{ $route.meta.title }}</div>
          </div>

          <div class="biaoqian">
            <el-dropdown>
              <template>
                <div class="admin_name">
                  {{ realname }}<span class="el-icon-arrow-down"></span>
                </div>
              </template>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="secede"
                  ><i class="el-icon-orange"></i>退出登錄</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-header>
      <el-main style="padding: 0" class="el-main scrollbar">
        <div class="tags-view-container">
          <TagsView />
        </div>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Breadcrumb from "@/components/Breadcrumb";
import Menu from "../views/menu.vue";
import TagsView from "../components/TagsView";
export default {
  components: {
    Menu,
    TagsView,
  },
  data() {
    return {
      indexpath: "",
      isCollapse: false,
      realname: "",
      menulists: [],
      breadcrumb: [
        {
          title: "首頁",
        },
        {
          title: "用戶管理",
        },
        {
          title: "管理員菜單",
        },
      ],
    };
  },
  watch: {
    $route(newVal) {
      if (newVal.meta.prevPath != undefined && newVal.meta.prevPath != null) {
        this.indexpath = newVal.meta.prevPath;
      } else {
        this.indexpath = newVal.name;
      }
    },
  },
  created() {
    this.menulist();
    this.realname = sessionStorage.getItem("realname");
    this.indexpath = window.sessionStorage.getItem("indexpath");
  },
  methods: {
    homepage() {
      this.$router.push({
        name: "homepage",
      });
    },

    async menulist() {
      const res = await this.$api.menulist({ mold_type: 1 });
      if (res.code == 200) {
        this.menulists = res.data.list;
      } else {
        this.menulists = [];
        this.$message.error("菜單列表獲取失敗");
      }
    },
    // 點擊圖片顯示隱藏
    picture() {
      this.isCollapse = !this.isCollapse;
    },

    click(indexpath) {
      this.indexpath = indexpath;
      window.sessionStorage.setItem("indexpath", indexpath);
    },
    linkClick(val) {
      this.click(val);
    },
    // 退出
    async secede() {
      const res = await this.$api.logout();
      if (res.code == 200) {
        window.sessionStorage.clear();
        this.$router.push({
          path: "/",
        });
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.home {
  min-height: 100vh;
  height: auto;
  ::v-deep li.el-menu-item.is-active {
    background-color: #258ff8 !important;
  }
  .isActive {
    background-color: #258ff8 !important;
    color: white !important;
  }

  .activetag {
    color: #409eff !important;
    background: white !important;
  }
  .home-dj {
    .logo {
      width: 100%;
      height: 64px;
      background-color: #001529;
      display: flex;
      justify-content: space-around;
      align-items: center;
      i,
      h1 {
        color: #fff;
      }
    }
  }
  .container {
    .header-top {
      background-color: #fff;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .home-left {
        display: flex;
        align-items: center;
        i {
          font-size: 24px;
          margin-left: 10px;
        }
        .header_bottom_right {
          margin-left: 10px;
        }
      }
      .biaoqian {
        display: flex;
        .admin_name {
          cursor: pointer;
          span {
            padding: 0;
            margin-left: 10px;
          }
        }
      }
      .el-dropdown {
        display: flex;
        align-items: center;

        justify-content: inherit;
        img {
          border-radius: 25px;
        }
        span {
          padding: 0 20px;
        }
      }
    }
  }
}
#echarts {
  width: 500px;
  height: 400px;
}
.el-main {
  // overflow: hidden;
  // min-width: 1200px;

  background-color: #f0f2f5;
  .main-left {
    background-color: #fff;
    margin-top: 2px;
    .el-breadcrumb {
      line-height: 2;
      margin-left: 35px;
    }
    .p1 {
      margin-left: 35px;
      font-weight: 700;
    }
    .main-left-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .main-left-xwh {
        display: flex;
        align-items: center;
        margin-left: 35px;
        .main-left-yz {
          width: 72px;
          height: 72px;
          img {
            width: 72px;
            height: 72px;
            border-radius: 50%;
          }
        }
        .main-left-my {
          margin-left: 50px;

          span {
            font-weight: 500;
            font-size: 20px;
          }
          p {
            color: rgba(0, 0, 0, 0.45);
            // line-height: 22px;
            font-size: 14px;
          }
        }
      }
      .main-right-xm {
        display: flex;
        position: relative;
        margin-right: 35px;
        .main-right-xms {
          span {
            color: rgba(0, 0, 0, 0.45);
            font-size: 14px;
          }
          p {
            margin-left: 22px;
            font-size: 20px;
          }
        }

        .main-right-td {
          padding: 0 70px;
          span {
            color: rgba(0, 0, 0, 0.45);
            font-size: 14px;
          }
          .ppp {
            margin-left: 22px;
            margin-top: 20px;
            .span1 {
              font-size: 20px;
              color: #000;
            }
            .span2 {
              padding: 0 6px;
            }
          }
        }
        .main-right-xmfw {
          span {
            color: rgba(0, 0, 0, 0.45);
            font-size: 14px;
          }
          p {
            margin-left: 6px;
            font-size: 20px;
          }
        }
        .main-sx {
          position: absolute;
          top: 15px;
          left: 82px;
          width: 1px;
          height: 40px;
          background-color: rgba(0, 0, 0, 0.45);
        }
        .main-fwe {
          position: absolute;
          width: 1px;
          height: 40px;
          top: 15px;
          left: 222px;
          background-color: rgba(0, 0, 0, 0.45);
        }
      }
    }
  }
  .main-left-alipay {
    // width: 100%;
    display: flex;
    margin: 24px 24px 0;
    // padding: 0 22px;
    // margin: 24px 24px 0;

    .main-div {
      width: 63.1%;
      padding-right: 10px;
      .main-left-jxz {
        background-color: #fff;
        // width: 60.66666667%;
        // margin-top: 24px;
        // padding: 0 12px;
        border-bottom: 1px solid #e8e8e8;
        border-radius: 2px 2px 0 0;
        // margin-left: 20px;
        margin-bottom: 20px;
        .main-shang {
          // width: 66.66666667%;
          padding: 0 21px;
          border-bottom: 1px solid #ccc;
          .main-ss {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 18px 0;
            .main-span2 {
              color: red;
            }
          }
        }
        .main-main {
          display: flex;
          flex-wrap: wrap;

          .main-Alipay-a {
            flex: 33%;
            height: 100%;
            &:hover {
              box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
              position: relative;
              z-index: 1;
            }

            .main-Alipay-b {
              height: 95px;
              background-color: #fff;
              border: 1px solid #ccc;
              border-top: 0;
              padding: 24px;

              .main-Alipay-c {
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                img {
                  width: 24px;
                  height: 24px;
                  border-radius: 25px;
                }
                span {
                  margin-left: 12px;
                  font-size: 14px;
                  color: #ccc;
                }
              }
              .main-Alipay-d {
                font-size: 14px;
                color: rgba(0, 0, 0, 0.45);
              }
              .main-Alipay-e {
                display: flex;
                justify-content: space-between;
                margin-top: 8px;
                span {
                  color: rgba(0, 0, 0, 0.45);
                  font-size: 12px;
                }
              }
            }
          }
          & > .main-Alipay-a:nth-child(1) .main-Alipay-b {
            border-right: none;
          }
          & > .main-Alipay-a:nth-child(2) .main-Alipay-b {
            border-right: none;
          }
          & > .main-Alipay-a:nth-child(4) .main-Alipay-b {
            border-right: none;
          }
          & > .main-Alipay-a:nth-child(5) .main-Alipay-b {
            border-right: none;
          }
        }
      }
      .main-left-ks {
        background-color: #fff;
        // width: 60.66666667%;
        border-radius: 2px 2px 0 0;
        .main-left-dt {
          padding: 18px 21px;
          border-bottom: 1px solid #ccc;
        }
        .main-left-name {
          padding: 24px;
          .main-left-name-a {
            border-bottom: 1px solid #e8e8e8;
            &:nth-child(6) {
              border: none;
            }
            .main-left-name-b {
              padding: 7px;
              display: flex;
              .main-left-name-logo {
                width: 24px;
                height: 24px;
                img {
                  width: 24px;
                  height: 24px;
                }
              }
              .main-left-name-kf {
                padding: 0 15px;
                .main-left-name-gx {
                  padding: 3px 0;
                  span {
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.65);
                  }
                  a {
                    color: #f5222d;
                    text-decoration: none;
                    font-size: 14px;
                  }
                }
                .main-left-sj {
                  font-size: 14px;
                  color: rgba(0, 0, 0, 0.45);
                  padding: 6px 0px;
                }
              }
            }
          }
        }
      }
    }

    .main-right-ks {
      // background-color: #fff;
      margin: 0 10px;
      width: 35%;
      .main-right-ksdh {
        background-color: #fff;
        .main-right-bj {
          padding: 17.5px 24px;
          color: rgba(0, 0, 0, 0.85);
          font-weight: 500;
          font-size: 16px;
          background: transparent;
          border-bottom: 1px solid #e8e8e8;
          border-radius: 2px 2px 0 0;
          zoom: 1;
        }
        .main-right-cz {
          // display: flex;
          padding: 20px 0 8px 24px;
          a {
            color: rgba(0, 0, 0, 0.65);
            display: inline-block;
            font-size: 14px;
            margin-bottom: 13px;
            width: 25%;
            &:hover {
              color: red;
            }
          }
        }
      }
      .main-right-zs {
        margin-bottom: 24px;
        background: #fff;
        border-radius: 2px;
        margin-top: 20px;
        .main-right-xx {
          padding: 17.5px 24px;
          color: rgba(0, 0, 0, 0.85);
          font-weight: 500;
          font-size: 16px;
          background: transparent;
          border-bottom: 1px solid #e8e8e8;
          border-radius: 2px 2px 0 0;
          zoom: 1;
        }
        .main-echarts {
          width: 500px;
          height: 400px;
        }
      }
      .main-right-cxy {
        background-color: #fff;
        .main-right-tda {
          padding: 17.5px 24px;
          color: rgba(0, 0, 0, 0.85);
          font-weight: 500;
          font-size: 16px;
          background: transparent;
          border-bottom: 1px solid #e8e8e8;
          border-radius: 2px 2px 0 0;
          zoom: 1;
        }
        .main-right-zh {
          padding: 24px;
          zoom: 1;
          .main-right-bzz {
            .main-right-zzb {
              display: flex;
              flex-wrap: wrap;
              div {
                width: 50%;
                display: block;
                padding: 1px 0;
                a {
                  display: block;
                  margin: 12px 0;
                  line-height: 24px;
                  height: 24px;

                  img {
                    width: 24px;
                    height: 24px;
                  }
                  span {
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.65);
                    line-height: 24px;
                    max-width: 100px;
                    vertical-align: top;
                    margin-left: 12px;
                    -webkit-transition: all 0.3s;
                    transition: all 0.3s;
                    display: inline-block;
                    &:hover {
                      color: red;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background: #f0f2f5;
  .el-footer {
    margin: 48px 0 24px 0;
    padding: 0 16px;
    text-align: center;
    .footer-s {
      margin-bottom: 8px;
      a {
        margin-right: 40px;
        color: rgba(0, 0, 0, 0.45);
      }
    }
    .footer-x {
      font-size: 14px;
      a {
        color: red;
      }
    }
  }
}
// .el-menu-vertical-demo:not(.el-menu--collapse) {
//   width: 255px;
//   // min-height: 400px;
// }
.el-aside {
  color: #fff;
  min-height: 100%;
  height: auto;
  position: relative;
  background: #001529;
  .header {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 10px;

    // img {
    //   margin-left: 20px;
    //   width: 30px;
    //   height: 30px;
    // }
    .sn {
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      // color: #fff;
      // font-size: 18px;
      // font-weight: 500;
      // .ssp {
      //   padding: 0 15px;
      // }
      width: 180px;
      img {
        width: 100%;
      }
    }
  }
  .home_sy {
    height: 56px;
    color: rgba(255, 255, 255, 0.65);
    padding: 0 20px;
    line-height: 56px;
    cursor: pointer;
  }

  .home_sy:hover {
    background-color: rgb(32, 41, 50);
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    min-width: 200px;
  }
  // ::v-deep .el-submenu:hover {
  //   color: #fff;
  // }
  ::v-deep .el-menu {
    border: none;
    // margin-top: 50px;
  }
}
.tags-view-container {
  margin-top: 1px;
  height: 36px;
  width: 100%;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 12%), 0 0 3px 0 rgb(0 0 0 / 4%);
}
</style>
