import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "login",
    meta: { title: "套店管理平台" }, // 澳創雲管理平台
  },
  {
    path: "/login",
    name: "login",
    meta: { title: "套店管理平台" },
    component: () => import("../views/Login.vue"),
  },

  {
    path: "/Home",
    name: "Home",
    component: Home,
    redirect: {
      path: "Home/homepage",
    },
    children: [
      {
        path: "homepage",
        name: "homepage",
        component: () => import("../views/dashboard/homepage.vue"),
        meta: { title: "首頁" },
      },
      // 系統管理
      {
        path: "versionmanagement",
        name: "versionmanagement",
        component: () => import("../views/system/versionmanagement.vue"),
        meta: { title: "版本管理" },
      },
      {
        path: "trademanagement",
        name: "trademanagement",
        component: () => import("../views/system/trademanagement.vue"),
        meta: { title: "行業管理" },
      },
      // 商戶
      {
        path: "shopManage",
        name: "shopManage",
        component: () => import("../views/merchant/shopManage.vue"),
        meta: { title: "店鋪管理" },
      },
      {
        path: "agentManage",
        name: "agentManage",
        component: () => import("../views/merchant/agentManage.vue"),
        meta: { title: "商戶管理" },
      },
      {
        path: "agentGoods",
        name: "agentGoods",
        component: () => import("../views/merchant/agentGoods.vue"),
        meta: { title: "商品庫" },
      },
      {
        path: "shopGoods",
        name: "shopGoods",
        component: () => import("../views/merchant/shopGoods.vue"),
        meta: { title: "店鋪商品" },
      },
      {
        path: "astray",
        name: "astray",
        component: () => import("../views/merchant/astray.vue"),
        meta: { title: "丟失商品" },
      },
      {
        path: "task",
        name: "task",
        component: () => import("../views/task/list.vue"),
        meta: { title: "Excel處理" },
      },
      //客服績效
      {
        path: "staffmoney",
        name: "staffmoney",
        component: () => import("../views/staffmoney/list.vue"),
        meta: { title: "客服績效" },
      },
      // 用戶
      {
        path: "administratormenu",
        name: "administratormenu",
        component: () => import("../views/user/administratormenu.vue"),
        meta: { title: "管理員管理" },
      },
      {
        path: "role",
        name: "role",
        component: () => import("../views/user/role.vue"),
        meta: { title: "角色管理" },
      },
      {
        path: "menuManage",
        name: "menuManage",
        component: () => import("../views/user/menuManage.vue"),
        meta: { title: "菜單管理" },
      },

      // 員工
      {
        path: "staffList",
        name: "staffList",
        meta: { title: "員工列表" },
        component: () => import("../views/staffManage/staffList.vue"),
      },
      {
        path: "roleManage",
        name: "roleManage",
        meta: { title: "角色管理" },
        component: () => import("../views/staffManage/roleManage.vue"),
      },
      {
        path: "barcode",
        name: "barcode",
        meta: { title: "商品條碼" },
        component: () => import("../views/barcode/list.vue"),
      },
      {
        path: "member",
        name: "member",
        meta: { title: "會員管理" },
        component: () => import("../views/member/list.vue"),
      },
      {
        path: "record",
        name: "record",
        meta: { title: "進店記錄" },
        component: () => import("../views/member/record.vue"),
      },
      {
        path: "order",
        name: "order",
        meta: { title: "訂單管理" },
        component: () => import("../views/order/index.vue"),
      },
      {
        path: "cashier",
        name: "cashier",
        meta: { title: "收銀app更新" },
        component: () => import("../views/app/cashier.vue"),
      },
      {
        path: "shopset",
        name: "shopset",
        meta: { title: "店鋪配置" },
        component: () => import("../views/merchant/shopset.vue"),
      },
      {
        path: "finance",
        name: "finance",
        meta: { title: "財務對賬" },
        component: () => import("../views/finance/list.vue"),
      },
      {
        path: "goodsSellTotal",
        name: "goodsSellTotal",
        meta: { title: "商品銷量" },
        component: () => import("../views/finance/goodsSellTotal.vue"),
      },
      {
        path: "shopSellTotal",
        name: "shopSellTotal",
        meta: { title: "店鋪銷量" },
        component: () => import("../views/finance/shopSellTotal.vue"),
      },
      {
        path: "dataBoard",
        name: "dataBoard",
        meta: { title: "銷量走勢" },
        component: () => import("../views/finance/dataBoard.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (to.path === "/login") {
    next();
  } else {
    const token = sessionStorage.getItem("admintoken");
    if (!token) {
      next("/login");
    } else {
      next();
    }
  }
});
export default router;
