import dayjs from "dayjs";
const dataFilter = {
  //日期过滤T
  getTime: function (value) {
    var date = new Date(value); //获取一个时间对象
    let y = date.getFullYear(); // 获取完整的年份(4位,1970)
    let m =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1; // 获取月份(0-11,0代表1月,用的时候记得加上1)
    let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate(); // 获取日(1-31)
    return y + "-" + m + "-" + d;
  },

  //将时间戳转换为YYYY-MM-DD,*1000把十位时间戳转化为13位
  setDate: function (val) {
    return val ? dayjs(val * 1000).format("YYYY-MM-DD HH:mm:ss") : "";
  },
};
function formatDate(value) {
  var date = new Date(value); //获取一个时间对象
  let y = date.getFullYear(); // 获取完整的年份(4位,1970)
  let m =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1; // 获取月份(0-11,0代表1月,用的时候记得加上1)
  let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate(); // 获取日(1-31)
  return y + "-" + m + "-" + d;
}
function formatMonth(value) {
  var date = new Date(value); //获取一个时间对象
  let y = date.getFullYear(); // 获取完整的年份(4位,1970)
  let m =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1; // 获取月份(0-11,0代表1月,用的时候记得加上1)
  return y + "-" + m;
}
export default {
  dataFilter,
  formatDate,
  formatMonth,
};
