// 日期格式化
export function parseTime(time, pattern) {
    if (arguments.length === 0 || !time) {
        return null
    }
    const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    let formatObj
    if (typeof time === 'object') {
        date = time
        //若是日期类型则正常转换
         formatObj = {
            y: date.getFullYear(),
            m: date.getMonth() + 1,
            d: date.getDate(),
            h: date.getHours(),
            i: date.getMinutes(),
            s: date.getSeconds(),
            a: date.getDay()
        }
    } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
            time = parseInt(time)
        } else if (typeof time === 'string') {
            time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = parseInt(time) * 1000
        }
        date = new Date(time)
        //若是时间戳需要加8小时
         formatObj = {
            y: date.getFullYear(),
            m: date.getMonth() + 1,
            d: date.getDate(),
            h: date.getHours()+8,
            i: date.getMinutes(),
            s: date.getSeconds(),
            a: date.getDay()
        }
    }


    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value]
        }
        if (result.length > 0 && value < 10) {
            value = '0' + value
        }
        return value || 0
    })
    return time_str

}

/**
 * 表格时间格式化
 */
// export function formatDate(cellValue) {
//     if (cellValue == null || cellValue == "") return "";
//     var date = new Date(cellValue)
//     var year = date.getFullYear()
//     var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
//     var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
//     var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
//     var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
//     var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
//     return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
// }
//

/**
 * 格式化日期
 * @param {string | number | Date} value 指定日期
 * @param {string} format 格式化的规则
 * @example
 * ```js
 * formatDate();
 * formatDate(1603264465956);
 * formatDate(1603264465956, "h:m:s");
 * formatDate(1603264465956, "Y年M月D日");
 * ```
 */
export function formatDate(value = Date.now(), format = "Y-m-d H:i:s") {
    if (value == null || value == "") return "";

    if ((typeof value === 'string') && (/^[0-9]+$/.test(value))) {
        value = parseInt(value)
    } else if (typeof value === 'string') {
        value = value.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
    }
    if ((typeof value === 'number') && (value.toString().length === 10)) {
        value = parseInt(value) * 1000 + (8 * 60 * 60 * 1000);
    }
    const formatNumber = n => `0${n}`.slice(-2);
    const date = new Date(value);
    const formatList = ["Y", "m", "d", "H", "i", "s"];
    const resultList = [];
    resultList.push(date.getFullYear().toString());
    resultList.push(formatNumber(date.getMonth() + 1));
    resultList.push(formatNumber(date.getDate()));
    resultList.push(formatNumber(date.getHours()));
    resultList.push(formatNumber(date.getMinutes()));
    resultList.push(formatNumber(date.getSeconds()));
    for (let i = 0; i < resultList.length; i++) {
        format = format.replace(formatList[i], resultList[i]);
    }
    return format;
}