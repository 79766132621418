<template>
  <div :class="[isCollapse ? 'el-menu--collapse' : '']">
    <template v-for="item in menuData">
      <el-submenu
        v-if="item.children"
        :index="item.menu_name"
        :key="item.id"
      >
        <template slot="title">
          <span slot="title">
            <svg-icon :icon-class="item.icon" style="margin-right: 10px" />{{
              item.menu_name
            }}
          </span>
        </template>
        <MenuTree :menu-data="item.children" @linkClick="linkClick" />
      </el-submenu>
      <el-menu-item
        v-else
        :index="item.path"
        :key="item.id"
        @click="linkClick(item.path)"
      >
        <span slot="title">
          <svg-icon :icon-class="item.icon" style="margin-right: 10px" />{{
            item.menu_name
          }}
        </span>
      </el-menu-item>
    </template>
  </div>
</template>
<script>
export default {
  name: "MenuTree",
  props: {
    isCollapse: {
      type: Boolean,
    },
    menuData: {
      type: Array,
      default: [],
    },
  },
  methods: {
    linkClick(val) {
      this.$emit("linkClick", val);
    },
  },
};
</script>
