import request from "../utils/request"

 function http(url, data, method, params) {
    return request({
        url: url,
        method: method,
        data: data,
        params:params
    })
}
export default http