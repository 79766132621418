import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "../src/assets/css/blbo.css";
import * as echarts from "echarts";
import { MessageBox } from "element-ui";
import { Message } from "element-ui";
import "./assets/icon";
import moment from "moment"; //引入moment
import locale from "element-ui/lib/locale/lang/zh-TW.js";
Vue.use(ElementUI, { locale });

Vue.prototype.$echarts = echarts;
// 引入ant-design
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
Vue.use(Antd);
Vue.config.productionTip = false;
import http from "../src/utils/http";
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;
Vue.prototype.$http = http;
import api from "./api/index";
Vue.prototype.$api = api;
import modal from "./utils/modal";
Vue.prototype.$modal = modal;
import "@/assets/scss/index.scss"; // global css
moment.locale("zh-cn");
import { parseTime, formatDate } from "./utils/tools";
Vue.prototype.parseTime = parseTime;
Vue.prototype.formatDate = formatDate;
import filter from "./utils/filter";
for (let key in filter.dataFilter) {
  Vue.filter(key, filter.dataFilter[key]);
}
Vue.prototype.time = filter;
//萤石
//import EZUIKit from 'ezuikit-js';

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
