import axios from "axios";
import { Notification, MessageBox, Message, Loading } from "element-ui";
import errorCode from "@/utils/errorCode";
import cache from "@/plugins/cache";

let downloadLoadingInstance;
// 是否顯示重新登錄
export let isRelogin = { show: false };

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 創建axios實例
const service = axios.create({
  // 超時
  // timeout: 10000
});

// request攔截器
service.interceptors.request.use(
  (config) => {
    if (
      sessionStorage.getItem("admintoken") != null &&
      sessionStorage.getItem("admintoken") != undefined
    ) {
      config.headers.admintoken = sessionStorage.getItem("admintoken");
    }

    // 是否需要防止數據重複提交
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false;

    // get請求映射params參數
    if (config.method === "get" && config.params) {
      let url = config.url + "?" + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    if (
      !isRepeatSubmit &&
      (config.method === "post" || config.method === "put")
    ) {
      const requestObj = {
        url: config.url,
        data:
          typeof config.data === "object"
            ? JSON.stringify(config.data)
            : config.data,
        time: new Date().getTime(),
      };
      const sessionObj = cache.session.getJSON("sessionObj");
      if (
        sessionObj === undefined ||
        sessionObj === null ||
        sessionObj === ""
      ) {
        cache.session.setJSON("sessionObj", requestObj);
      } else {
        const s_url = sessionObj.url; // 請求地址
        const s_data = sessionObj.data; // 請求數據
        const s_time = sessionObj.time; // 請求時間
        const interval = 100; // 間隔時間(ms)，小於此時間視為重複提交
        if (
          s_data === requestObj.data &&
          requestObj.time - s_time < interval &&
          s_url === requestObj.url
        ) {
          const message = "數據正在處理，請勿重複提交";
          console.warn(`[${s_url}]: ` + message);
          return Promise.reject(new Error(message));
        } else {
          cache.session.setJSON("sessionObj", requestObj);
        }
      }
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

// 響應攔截器
service.interceptors.response.use(
  (res) => {
    // 未設置狀態碼則默認成功狀態
    const code = res.data.code || 200;
    // 獲取錯誤信息
    const msg = errorCode[code] || res.data.msg || errorCode["default"];
    // 二進制數據則直接返回
    if (
      res.request.responseType === "blob" ||
      res.request.responseType === "arraybuffer"
    ) {
      return res.data;
    }
    if (code === 401) {
      if (!isRelogin.show) {
        isRelogin.show = true;
        MessageBox.confirm(
          "登錄狀態已過期，您可以繼續留在該頁面，或者重新登錄",
          "系統提示",
          {
            confirmButtonText: "重新登錄",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            isRelogin.show = false;
            // store.dispatch('logout').then(() => {
            sessionStorage.clear();
            location.href = "/#/login";
            // })
          })
          .catch(() => {
            isRelogin.show = false;
          });
      }
      return Promise.reject("無效的會話，或者會話已過期，請重新登錄。");
    } else if (code === 500) {
      Message({
        message: msg,
        type: "error",
      });
      return Promise.reject(new Error(msg));
    } else if (code !== 200) {
      Notification.error({
        title: msg,
      });
      return Promise.reject("error");
    } else {
      return res.data;
    }
  },
  (error) => {
    // console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "後端接口連接異常";
    } else if (message.includes("timeout")) {
      message = "系統接口請求超時";
    } else if (message.includes("Request failed with status code")) {
      message = "系統接口" + message.substr(message.length - 3) + "異常";
    }
    Message({
      message: message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
