import axios from "axios"
import request from "../utils/request"


export default {
    // 登录
    login(obj) {
        return request(
            {
                url: "/admin/passport",
                data: obj,
                method: 'post'
            }
        )
    },
    // 退出
    async logout(obj) {
        const res = await request(
            {
                url: "/admin/passport/logout",
                data: obj,
                method: 'post'
            }
        )
        return res
    },
    // 左侧菜单
    async menulist(obj) {
        const res = await request(
            {
                url: "/admin/Menu/getUserMenu",
                data: obj,
                method: 'post'
            }
        )
        return res
    },
    // 用户
    // 菜单管理
    // 列表
    async getMenu(obj) {
        const res = await request(
            {
                url: "/admin/Menu/list",
                data: obj,
                method: 'post'
            }
        )
        return res
    },
    // 添加
    async addMenu(obj) {
        // const res = await http('/admin/Menu/add', obj, 'post')
        const res = await request(
            {
                url: "/admin/Menu/add",
                data: obj,
                method: 'post'
            }
        )
        return res
    },
    // 编辑
    async editMenu(obj) {
        const res = await request(
            {
                url: "/admin/Menu/edit",
                data: obj,
                method: 'post'
            }
        )
        // const res = await http('/admin/Menu/edit', obj, 'post')
        return res
    },
    // 删除
    async delMenu(obj) {
        const res = await request(
            {
                url: "/admin/Menu/del",
                data: obj,
                method: 'post'
            }
        )
        // const res = await http('/admin/Menu/del', obj, 'post')
        return res
    },
    // 角色管理
    // 列表
    async getRole(obj) {
        const res = await request(
            {
                url: "/admin/role/list",
                data: obj,
                method: 'post'
            }
        )
        // const res = await http('/admin/role/list', obj, 'post')
        return res
    },
    // 添加
    async addRole(obj) {
        const res = await request(
            {
                url: "/admin/role/add",
                data: obj,
                method: 'post'
            }
        )
        // const res = await http('/admin/role/add', obj, 'POST',)
        return res
    },
    // 详情
    async roleInfo(obj) {
        const res = await request(
            {
                url: "/admin/role/detail",
                data: obj,
                method: 'post'
            }
        )
        // const res = await http('/admin/role/detail', obj, 'post')
        return res
    },
    // 编辑
    async editRole(obj) {
        const res = await request(
            {
                url: "/admin/role/edit",
                data: obj,
                method: 'post'
            }
        )
        // const res = await http('/admin/role/edit', obj, 'POST',)
        return res
    },
    // 删除
    async delRole(obj) {
        const res = await request(
            {
                url: "/admin/role/delete",
                data: obj,
                method: 'post'
            }
        )
        return res
    },
    // 管理员管理
    // 列表
    async userList(obj) {
        const res = await request(
            {
                url: "/admin/user/list",
                data: obj,
                method: 'post'
            }
        )
        return res
    },
    // 添加
    async addUser(obj) {
        const res = await request(
            {
                url: "/admin/user/add",
                data: obj,
                method: 'post'
            }
        )
        return res
    },
    // 用户详情
    async userDetail(obj) {
        const res = await request(
            {
                url: "/admin/user/detail",
                data: obj,
                method: 'post'
            }
        )
        return res
    },
    // 编辑
    async editUser(obj) {
        const res = await request(
            {
                url: "/admin/user/edit",
                data: obj,
                method: 'post'
            }
        )
        return res
    },
    // 删除
    async delUser(obj) {
        const res = await request(
            {
                url: "/admin/user/delete",
                data: obj,
                method: 'post'
            }
        )
        return res
    },
    // 商户
    // 商户管理
    async agentList(obj) {
        const res = await request(
            {
                url: "/admin/Agent/list",
                data: obj,
                method: 'post'
            }
        )
        return res
    },
    // 添加
    async addAgent(obj) {
        const res = await request(
            {
                url: "/admin/agent/add",
                data: obj,
                method: 'post'
            }
        )
        return res
    },
    // 详情
    async agentDetail(obj) {
        const res = await request(
            {
                url: "/admin/Agent/detail",
                data: obj,
                method: 'post'
            }
        )
        return res
    },
    // 编辑
    async editAgent(obj) {
        const res = await request(
            {
                url: "/admin/Agent/edit",
                data: obj,
                method: 'post'
            }
        )
        return res
    },
    // 删除
    async delAgent(obj) {
        const res = await request(
            {
                url: "/admin/Agent/del",
                data: obj,
                method: 'post'
            }
        )
        return res
    },
    // 审核
    async examineAgent(obj) {
        const res = await request(
            {
                url: "/admin/Agent/passExamine",
                data: obj,
                method: 'post'
            }
        )
        return res
    },

    // 店铺管理
    //  列表
    async getShop(obj) {
        const res = await request(
            {
                url: "/admin/shop/list",
                data: obj,
                method: 'post'
            }
        )
        return res
    },
    // 添加
    async addShop(obj) {
        const res = await request(
            {
                url: "/admin/shop/add",
                data: obj,
                method: 'post'
            }
        )
        return res
    },
    // 详情
    async shopDetail(obj) {
        const res = await request(
            {
                url: "/admin/shop/detail",
                data: obj,
                method: 'post'
            }
        )
        return res
    },
    // 编辑
    async editShop(obj) {
        const res = await request(
            {
                url: "/admin/shop/edit",
                data: obj,
                method: 'post'
            }
        )
        return res
    },
    // 审核
    async editStatus(obj) {
        const res = await request(
            {
                url: "/admin/shop/editStatus",
                data: obj,
                method: 'post'
            }
        )
        return res
    },

// 商户列表


    // 上传图片文件
    async uploadImage(req) {
        const config = {
            headers: {'Content-Type': 'multipart/form-data'}
        };
        const formdata = new FormData()
        formdata.append('file', req)
        const res = await axios.post('/api/pic', formdata, config)
        return res
    },


}